import React from 'react'
import './Shell.css'
import './bg.jpg'
import logo from './logo.svg'
import Search from './Search'

export default () => (
    <div>
        <header>
            <div className="header--container">
                <img src={logo} className="logo" alt="logo" />
                <h1>Recherche</h1>
            </div>
        </header>
        <article>
            <Search />
        </article>
    </div>
)
