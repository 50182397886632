import React, { useState, useRef, useEffect } from 'react'
import styles from './Search.module.css'
import PeopleResult from './PeopleResult'
import PagesResult from './PagesResult'
import { useDebounce } from 'use-debounce'

const onInputChange = setInput => ({ target: { value } }) => {
    setInput(value)
}

const onWithPeopleChange = setWithPeople => ({ target: { checked } }) => {
    setWithPeople(checked)
}

const onClearClick = ({ setInput, inputRef }) => () => {
    setInput('')
    inputRef.current.focus()
}

export default () => {
    const inputRef = useRef()
    const [input, setInput] = useState('')
    const [withPeople, setWithPeople] = useState(false)
    const [debouncedInput] = useDebounce(input, 500)
    useEffect(() => {
        inputRef.current.focus()
    }, [])
    return (
        <div>
            <div className={styles.wrapper}>
                <input
                    ref={inputRef}
                    className={styles.input}
                    type="text"
                    onChange={onInputChange(setInput)}
                    value={input}
                />
                {input && (
                    <i
                        className={'far fa-times-circle ' + styles.clear}
                        onClick={onClearClick({ setInput, inputRef })}
                    />
                )}
            </div>
            <div className={styles.options}>
                <label>
                    <input
                        type="checkbox"
                        className={styles.checkbox}
                        checked={withPeople}
                        onChange={onWithPeopleChange(setWithPeople)}
                    />
                    Inclure les personnes
                </label>
            </div>
            {withPeople && <PeopleResult query={debouncedInput} />}
            <PagesResult query={debouncedInput} />
        </div>
    )
}
