import axios from 'axios'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import styles from './PagesResult.module.css'
import commonStyles from './Result.module.css'
import Pagination from 'react-js-pagination'
import { head } from 'ramda'

const searchURI = 'https://www.googleapis.com/customsearch/v1'

const search = ({ setData, setPagination, pagination }) => async query => {
    const options = {
        key: 'AIzaSyCJI4XBk74lAxm_cBrH8yYjy_W0EAhK7Hw',
        cx: '018077331475671375259:xa77xesir4s',
        q: query,
        start: Math.max(1, (pagination.currentPage - 1) * pagination.count + 1),
    }

    const {
        data: {
            items,
            queries: { request },
        },
    } = await axios.get(`${searchURI}?${qs.stringify(options)}`)

    const { totalResults, count, startIndex } = head(request)
    setPagination({
        total: totalResults,
        count,
        currentPage: Math.ceil(startIndex / count),
    })
    setData(items)
}

const onPaginationChanged = ({
    setData,
    setPagination,
    query,
    pagination,
}) => pageNumber => {
    search({
        setData,
        setPagination,
        pagination: { ...pagination, currentPage: pageNumber },
    })(query)
}

export default ({ query }) => {
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        currentPage: 0,
        total: 0,
        count: 10,
    })
    useEffect(() => {
        query
            ? search({ setData, setPagination, pagination })(query)
            : setData([])
    }, [query])
    return (
        <div>
            {!query ? null : (
                <>
                    <h2 className={commonStyles.title}>Pages et documents</h2>

                    {data ? (
                        <>
                            {data.map(item => (
                                <div className={styles.item} key={item.cacheId}>
                                    <a
                                        href={item.link}
                                        className={styles.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span
                                            className={styles.title}
                                            dangerouslySetInnerHTML={{
                                                __html: item.title,
                                            }}
                                        />
                                        <span className={styles.url}>
                                            {item.link}
                                        </span>
                                    </a>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.htmlSnippet,
                                        }}
                                    />
                                </div>
                            ))}
                            {data.length > 0 && (
                                <Pagination
                                    onChange={onPaginationChanged({
                                        setData,
                                        setPagination,
                                        query,
                                        pagination,
                                    })}
                                    activePage={pagination.currentPage}
                                    itemsCountPerPage={pagination.count}
                                    totalItemsCount={pagination.total}
                                    pageRangeDisplayed={5}
                                    hideFirstLastPages={true}
                                    innerClass={styles.innerClass}
                                    activeClass={styles.activeClass}
                                    activeLinkClass={styles.activeLinkClass}
                                    itemClass={styles.itemClass}
                                    itemClassFirst={styles.itemClassFirst}
                                    itemClassPrev={styles.itemClassPrev}
                                    itemClassNext={styles.itemClassNext}
                                    itemClassLast={styles.itemClassLast}
                                    disabledClass={styles.disabledClass}
                                />
                            )}
                        </>
                    ) : (
                        <p>Pas de résultat</p>
                    )}
                </>
            )}
        </div>
    )
}
