import React, { useState, useEffect } from 'react'
import commonStyles from './Result.module.css'
import styles from './PeopleResult.module.css'
import axios from 'axios'
import { pipe, values, flatten, isEmpty, take } from 'ramda'

// const searchURI = 'http://contacts.heig-vd.ch/search'
const searchURI = 'https://cors.io/?http://contacts.heig-vd.ch/search'

const search = setData => async query => {
    const {
        data: { people },
    } = await axios.get(`${searchURI}/${query}.json`)
    const filter = pipe(
        values,
        flatten
    )
    const data = isEmpty(people) ? null : filter(people)
    setData(data)
}

export default ({ query }) => {
    const [data, setData] = useState([])
    useEffect(() => {
        query ? search(setData)(query) : setData([])
    }, [query])
    return (
        <div className={styles.wrapper}>
            {!query ? null : (
                <>
                    <h2 className={commonStyles.title}>Personnes</h2>
                    {data ? (
                        <>
                            {take(2, data).map(item => (
                                <div className={styles.item} key={item.id}>
                                    <span className={styles.name}>
                                        {item.first_name} {item.last_name}
                                    </span>
                                    <ul className={styles.list}>
                                        <li>
                                            <span>Campus</span>
                                            <span>St-Roch</span>
                                        </li>
                                        <li>
                                            <span>Téléphone</span>
                                            <span>+024 555 05 05</span>
                                        </li>
                                        <li>
                                            <span>Email</span>
                                            <span>
                                                bastien.rentsch@heig-vd.ch
                                            </span>
                                        </li>
                                    </ul>
                                    <span className={styles.link}>
                                        Voir la fiche complète
                                    </span>
                                </div>
                            ))}
                            {data.length > 2 && (
                                <div className={styles.more}>
                                    <div className={styles.ellipsis} />
                                    <span>
                                        <strong>
                                            {data.length - 2} autres personnes
                                        </strong>{' '}
                                        répondent à votre critère de recherche.
                                    </span>
                                    <a
                                        href="http://contacts.heig-vd.ch"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Se rendre sur l'annuaire des personnes
                                    </a>
                                </div>
                            )}
                        </>
                    ) : (
                        <p>Pas de résultat</p>
                    )}
                </>
            )}
        </div>
    )
}

// <div>
// <h2 className={commonStyles.title}>Personnes</h2>
// <div className={styles.item}>
//     <span className={styles.name}>Bastien Rentsch</span>
//     <ul className={styles.list}>
//         <li>
//             <span>Campus</span>
//             <span>St-Roch</span>
//         </li>
//         <li>
//             <span>Téléphone</span>
//             <span>+024 555 05 05</span>
//         </li>
//         <li>
//             <span>Email</span>
//             <span>bastien.rentsch@heig-vd.ch</span>
//         </li>
//     </ul>
//     <span className={styles.link}>Voir la fiche complète</span>
// </div>
// <div className={styles.item}>
//     <span className={styles.name}>René Rentsch</span>
//     <ul className={styles.list}>
//         <li>
//             <span>Campus</span>
//             <span>St-Roch</span>
//         </li>
//         <li>
//             <span>Téléphone</span>
//             <span>+024 555 05 05</span>
//         </li>
//         <li>
//             <span>Email</span>
//             <span>bastien.rentsch@heig-vd.ch</span>
//         </li>
//     </ul>
//     <span className={styles.link}>Voir la fiche complète</span>
// </div>
// <div className={styles.more}>
//     <div className={styles.ellipsis} />
//     <span>
//         <strong>5 autres personnes</strong> répondent à votre
//         critère de recherche.
//     </span>
//     <a href="#">Se rendre sur l'annuaire des personnes</a>
// </div>
// </div>
